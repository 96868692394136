<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Page Sections" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary mx-4" :to="{ name: 'Pages Content' }">
            View Pages
          </md-button>
          <md-button class="md-primary" :to="{ name: 'Pages Section Create' }">
            Add Section
          </md-button>
        </div>
      </div>
      <md-table
        v-model="sections.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start"></div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-icon>search</md-icon>
              <md-input placeholder="Search" v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Tag">{{ item.section_tag }}</md-table-cell>

          <md-table-cell md-label="Actions" md-numeric>
            <div class="table-actions">
              <md-button
                :disabled="updating"
                md-menu-trigger
                :to="{ name: 'Pages Section Edit', params: { id: item.id } }"
                class="bggreen btn-sm"
                >Edit</md-button
              >
              <md-button
                md-menu-trigger
                class="bggreen btn-icon"
                style="margin-left: 2px"
                @click="showDetails(item)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  />
                </svg>
              </md-button>
              <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-icon"
                @click="remove(item.id)"
                ><i class="material-icons">close</i></md-button
              >
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="sections.mdPage"
        :records="sections.mdCount"
        :per-page="sections.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title> Section Content </md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="section">
        <div v-if="section.type == 'text'">
          <div v-html="section.content"></div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("pages");
export default {
  name: "Advert",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
      show: false,
      section: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getSections", "deleteSection"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getSections(data);
    },
    async submitSearch() {
      await this.getSections({ keyword: this.search });
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deleteSection(id);
          this.deleting = false;
          this.toast("success", "Portfolio Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
    showDetails(section) {
      this.show = true;
      this.section = section;
    },
  },
  created() {},
  mounted() {
    this.getSections();
  },
  computed: {
    ...mapGetters(["sections", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.table-actions {
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>
